<template>
    <app-page>
        <template #page-header>
            <page-header v-if="!projectNotExists && project" :name="project.name" :loading="loading" no-search>
                <b-input v-if="creationMode" placeholder="Project name" v-model="project.name" @change="projectNameChanged(); projectChanged()" :formatter="kubernetesFormatter" required></b-input>
                <template v-else>
                    <a v-if="project.domains.length" :href="`https://`+project.domains[0]" target="_blank" class="btn btn-light mr-2" :title="project.domains[0]">
                        <i class="fas fa-link fa-fw"></i> Open
                    </a>
                    <a :href="'https://github.com/Eye-InMedia/'+project.name" target="_blank" class="btn btn-light mr-2"
                       :class="{'text-success': (project.github_status === 'Jenkins WebHook' || project.github_status === 'GitHubActions'), 'text-danger': project.github_status === 'Not found' }"
                       v-b-tooltip :title="'Github Status: ' + project.github_status">
                        <i class="fab fa-github fa-fw"></i>
                        Github {{ project.github_status }}
                    </a>
                    <a v-if="project.github_status === 'Jenkins WebHook'" :href="'http://jenkins.eyeinsansfil.com:8080/job/'+project.name+'%20'+$store.state.cluster.toUpperCase()+ '/'" target="_blank"
                       class="btn btn-light" :class="project.jenkins_job === 'Ok' ? 'text-success' : 'text-danger'" v-b-tooltip :title="'Jenkins Status: ' + project.jenkins_job">
                        <i class="fab fa-jenkins fa-fw"></i>
                        Jenkins {{ project.jenkins_job }}
                    </a>
                    <b-btn class="ml-2" @click="saveProject(project)" variant="success" :disabled="saving || !project.name">
                        <b-spinner v-if="saving" variant="primary" small type="grow" label="Spinning"></b-spinner>
                        Save changes
                    </b-btn>
                    <b-dropdown right variant="light" class="ml-2" no-caret>
                        <template #button-content>
                            <i class="fas fa-ellipsis-v fa-fw"></i>
                        </template>
                        <e-button-confirm variant="transparent" @confirmed="deleteProject" class="text-danger dropdown-item">Delete project</e-button-confirm>
                    </b-dropdown>
                </template>
            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm">
            <b-card v-if="projectNotExists">
                <p>Project has not been deployed on {{ $store.state.cluster }}</p>
                <b-btn variant="primary" @click="startDeployment">Start deployment on {{ $store.state.cluster }}</b-btn>
            </b-card>
            <template v-else-if="project">
                <b-tabs v-model="tabIndex" class="main-tabs" content-class="mt-3" align="center" pills>
                    <b-tab title="Project settings" active>
                        <b-card class="text-left my-3">
                            <b-card-title>Status</b-card-title>

                            <b-card-text>
                                <strong class="pr-2">Pods replicas</strong>
                                <b-form-spinbutton v-model="project.replicas" min="0" :max="project.maxReplicas" @input="projectChanged" inline></b-form-spinbutton>
                                <e-button-confirm class="ml-2 float-right" size="sm" variant="danger" @confirmed="rebootPods()" :disabled="rebooting" v-if="project.pods.length">
                                    <b-spinner v-if="rebooting" variant="warning" small type="grow" label="Spinning"></b-spinner>
                                    Reboot pods
                                </e-button-confirm>
                            </b-card-text>

                            <b-list-group class="compact">
                                <b-list-group-item v-for="pod in project.pods" :key="pod.name">
                                    <router-link :to="'/pods/'+project.name">{{ pod.name }}</router-link>
                                    <b-btn class="ml-2" size="sm" @click="downloadLogs(pod.name)"><i class="fas fa-file-text"></i> Download logs</b-btn>
                                    <b-badge variant="light" class="float-right m-1" :title="$moment(pod.startTime).format('YYYY-MM-DD HH:mm')">{{ pod.startTime|dateAgo }}</b-badge>
                                    <b-badge :variant="pod.restarts >= 3 ? 'danger' : 'success'" class="float-right m-1">Restarts: {{ pod.restarts|number }}</b-badge>
                                    <b-badge variant="success" class="float-right m-1" v-if="pod.status === 'Running'">{{ pod.status }}</b-badge>
                                    <b-badge variant="danger" class="float-right m-1" v-else>{{ pod.status }}</b-badge>
                                </b-list-group-item>
                            </b-list-group>

                            <template v-if="project.jobs">
                                <b-card-text class="mt-3">
                                    <strong class="pr-2">Last Github Worflow jobs</strong>
                                </b-card-text>

                                <b-list-group class="compact">
                                    <b-list-group-item v-for="job in project.jobs" :key="job.id">
                                        <a :href="job.html_url" target="_blank">{{ job.name }}</a>
                                        <b-badge variant="light" class="float-right m-1" :title="$moment(job.created_at).format('YYYY-MM-DD HH:mm')">{{ job.created_at|dateAgo }}</b-badge>
                                        <b-badge variant="success" class="float-right m-1" v-if="job.status === 'completed'">{{ job.status }}</b-badge>
                                        <b-badge variant="warning" class="float-right m-1" v-else>{{ job.status }}</b-badge>
                                        <template v-if="job.conclusion">
                                            <b-badge variant="danger" class="float-right m-1" v-if="job.conclusion === 'failure'">{{ job.conclusion }}</b-badge>
                                            <b-badge variant="success" class="float-right m-1" v-else>{{ job.conclusion }}</b-badge>
                                        </template>
                                    </b-list-group-item>
                                </b-list-group>
                            </template>
                        </b-card>
                        <b-card class="text-left my-3" no-body>
                            <b-card-body>
                                <b-card-title>Config</b-card-title>

                                <b-form-group label="Description">
                                    <b-textarea v-model="project.description"></b-textarea>
                                </b-form-group>

                                <b-form-group label="Tags">
                                    <tags-input v-model="project.tags" :typeahead="true"></tags-input>
                                </b-form-group>

                                <b-card-text>
                                    <strong>Docker Image</strong>
                                    <b-input v-model="project.dockerImage" @change="projectChanged"></b-input>
                                </b-card-text>
                                <b-card-text>
                                    <strong>Docker Command</strong>
                                    <b-checkbox v-model="useDefaultDockerCommand">Use default Dockerfile command</b-checkbox>
                                    <b-input v-if="!useDefaultDockerCommand" v-model="project.dockerCommand" @change="projectChanged"></b-input>
                                </b-card-text>
                                <b-card-text class="mt-3">
                                    <strong>Domains</strong>
                                    <i class="fas fa-edit ml-2 pointer" @click="editDomains()"></i>
                                    <i class="fas fa-plus ml-2 pointer" @click="addDomain()"></i>
                                </b-card-text>
                                <b-list-group class="compact">
                                    <b-list-group-item v-for="(domain, domainIndex) in project.domains" :key="project.name+'-domain-'+domainIndex">
                                        <e-button-confirm @confirmed="removeDomain(domainIndex)" size="sm" variant="light" class="text-danger float-right">
                                            <i class="fas fa-close fa-fw"></i>
                                        </e-button-confirm>
                                        <a class="mt-1 d-block" v-if="!project.edit_domains" :href="'https://'+domain" target="_blank">{{ domain }}</a>
                                        <b-form v-else inline>
                                            <b-input class="w-100 mr-3" v-model="project.domains[domainIndex]" @change="projectChanged"></b-input>
                                        </b-form>
                                    </b-list-group-item>
                                </b-list-group>

                                <b-card-text class="mt-3">
                                    <strong class="pr-2">Environment variables</strong>
                                    <i class="fas fa-edit ml-2 pointer" @click="editEnvironmentVariable"></i>
                                    <i class="fas fa-plus ml-2 pointer" @click="addEnvironmentVariable"></i>
                                    <i class="fas fa-file-import ml-2 pointer" @click="showEnvImportModal = true"></i>
                                    <b-btn class="ml-2" variant="warning" size="sm" @click="addFirewallEnvironmentVariable">Add firewall exception</b-btn>
                                </b-card-text>

                                <b-list-group>
                                    <b-list-group-item v-for="(variable, index) in project.environmentVariables" :key="project.name+'-env-'+variable.name">
                                        <b-row>
                                            <b-col>
                                                <b-select v-if="project.edit_env" v-model="variable.type" @change="projectChanged" :lazy="true">
                                                    <option value="Text">Text</option>
                                                    <option value="Array">Array</option>
                                                </b-select>
                                                <span v-else class="mr-2">{{ variable.type }}</span>
                                            </b-col>
                                            <b-col>
                                                <b-input v-if="project.edit_env" v-model="variable.name" @change="projectChanged" :lazy="true"></b-input>
                                                <span v-else class="mr-2">{{ variable.name }}</span>
                                            </b-col>
                                            <b-col>
                                                <template v-if="variable.type === `Array`">
                                                    <div class="text-center">
                                                        <b-button v-b-toggle="variable.name + `-collapse`" variant="secondary">See value</b-button>
                                                    </div>
                                                    <b-collapse :id="variable.name + `-collapse`">
                                                        <b-list-group class="mt-2">
                                                            <b-list-group-item v-for="(subvalue, index) of variable.values" :key="index">
                                                                <b-input-group v-if="project.edit_env">
                                                                    <b-input v-model="variable.values[index]" @change="projectChanged" :lazy="true"></b-input>
                                                                    <b-input-group-append>
                                                                        <b-btn @click="variable.values.splice(index, 1); projectChanged()" class="float-right">
                                                                            <i class="fas fa-trash"></i>
                                                                        </b-btn>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                                <span v-else>{{ subvalue }}</span>
                                                            </b-list-group-item>
                                                            <b-list-group-item v-if="project.edit_env" @click="variable.values.push(``)" class="text-center pointer">
                                                                <i class="fas fa-plus"></i> Add element
                                                            </b-list-group-item>
                                                        </b-list-group>
                                                    </b-collapse>
                                                </template>
                                                <template v-else>
                                                    <b-input v-if="project.edit_env" v-model="variable.value" @change="projectChanged" :lazy="true"></b-input>
                                                    <span v-else class="mr-2">{{ variable.value }}</span>
                                                </template>
                                            </b-col>
                                            <b-col cols="1" v-if="project.edit_env">
                                                <e-button-confirm @confirmed="removeEnvironmentVariable(index)" variant="link" class="text-dark">
                                                    <i class="fas fa-trash"></i>
                                                </e-button-confirm>
                                            </b-col>
                                        </b-row>

                                    </b-list-group-item>
                                </b-list-group>

                                <b-card-text class="mt-3">
                                    <strong class="pr-2">Services</strong>
                                    <i class="fas fa-edit ml-2 pointer" @click="editServices()"></i>
                                    <i class="fas fa-plus ml-2 pointer" @click="addService()"></i>
                                </b-card-text>
                                <b-list-group>
                                    <b-list-group-item v-for="(service, serviceIndex) in project.services" :key="project.name+'-service-'+serviceIndex">
                                        <e-button-confirm size="sm" variant="danger" @confirmed="removeService(serviceIndex)" class="float-right">Delete service</e-button-confirm>

                                        <b-form-group v-if="project.edit_services" label="Name">
                                            <b-input v-model="service.name" @change="projectChanged" :lazy="true"></b-input>
                                        </b-form-group>
                                        <span v-else class="mr-2">{{ service.name }}</span>

                                        <b-form-group v-if="project.edit_services" label="Type">
                                            <b-select v-model="service.type" @input="projectChanged">
                                                <option value="ClusterIP">ClusterIP</option>
                                                <option value="LoadBalancer">LoadBalancer</option>
                                            </b-select>
                                        </b-form-group>
                                        <b-badge v-else variant="info" pill>{{ service.type }}</b-badge>

                                        <b-form-group v-if="service.type === 'LoadBalancer' && project.edit_services">
                                            <b-input v-model="service.ip" @change="projectChanged"></b-input>
                                            <small>Note: IP must already be on azure in applications-ip-resource-group</small>
                                        </b-form-group>
                                        <div v-else-if="service.type === 'LoadBalancer' && service.ip">IP: {{ service.ip }}</div>

                                        <div>
                                            Ports
                                            <i class="fas fa-plus ml-2 pointer" v-if="project.edit_services" @click="addPort(service)"></i>
                                            <b-container v-if="project.edit_services">
                                                <b-row v-for="(port, portIndex) in service.ports" :key="project.name+'-service-'+serviceIndex+'-port-'+portIndex">
                                                    <b-col>
                                                        <b-input v-model="port.name" @change="projectChanged"></b-input>
                                                    </b-col>
                                                    <b-col>
                                                        <b-select v-model.number="port.protocol" @input="projectChanged">
                                                            <option value="TCP">TCP</option>
                                                            <option value="UDP">UDP</option>
                                                        </b-select>
                                                    </b-col>
                                                    <b-col>
                                                        <b-input v-model.number="port.port" @change="projectChanged"></b-input>
                                                    </b-col>
                                                    <b-col cols="1">
                                                        <e-button-confirm @confirmed="removePort(service, portIndex)" variant="link" class="text-dark">
                                                            <i class="fas fa-trash"></i>
                                                        </e-button-confirm>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                            <ul class="mb-0" v-else>
                                                <li v-for="port in service.ports"><span v-if="port.name">{{ port.name }}: </span>{{ port.port }} ({{ port.protocol }})</li>
                                            </ul>
                                        </div>
                                    </b-list-group-item>
                                </b-list-group>

                                <b-card-text class="mt-3">
                                    <div>
                                        <strong class="pr-2">Pipeline Type</strong>
                                        <b-dropdown v-if="!project.pipeline" right variant="primary" class="ml-2 float-right" no-caret>
                                            <template #button-content>
                                                Add pipeline
                                                <i class="fas fa-ellipsis-v fa-fw"></i>
                                            </template>
                                            <b-dropdown-item v-for="pipelineType in pipelineTypes" :key="pipelineType.value" @click="addPipeline(pipelineType.value)">{{ pipelineType.text }}</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </b-card-text>
                                <b-card v-if="project.pipeline">
                                    {{ project.pipeline.name }}
                                    <div v-if="project.pipeline.type === 'github_actions'">
                                        <b-checkbox v-model="project.pipeline.includeCypressAction" @change="onCypressActionChanged" name="include-cypress-checkbox">
                                            Include Cypress Action in Workflow
                                        </b-checkbox>
                                        <div v-if="project.pipeline.includeCypressAction">
                                            Cypress Record Key
                                            <b-input v-model="project.pipeline.cypressRecordKey" @update="onCypressRecordKeyChanged"/>
                                        </div>
                                        <div class="mt-3" v-if="project.pipeline.includeCypressAction">
                                            <div v-if="project.cluster === `live`">
                                                Cypress Action daily schedule time <em>(NOTE: runs against default branch only)</em>
                                                <e-datetime-picker type="time" only-time :value="cypressScheduledTime" @input="onCypressCronExpressionChanged"></e-datetime-picker>
                                            </div>
                                            <b-card-text class="mt-3">
                                                <strong class="pr-2">Cypress Pipeline variables</strong>
                                                <i class="fas fa-edit ml-2 pointer" @click="editCypressPipelineVariable()"></i>
                                                <i class="fas fa-plus ml-2 pointer" @click="addCypressPipelineVariable()"></i>
                                            </b-card-text>
                                            <b-list-group>
                                                <b-list-group-item v-for="(variable, index) in project.cypressPipelineVariables" :key="variable.name">
                                                    <b-row v-if="project.edit_pipeline_var">
                                                        <b-col>
                                                            <b-input-group>
                                                                <b-input-group-text>CYPRESS_</b-input-group-text>
                                                                <b-input class="text-uppercase" v-model="variable.name" @change="onCypressPipelineVariablesChanged" :lazy="true"></b-input>
                                                            </b-input-group>
                                                        </b-col>
                                                        <b-col>
                                                            <template>
                                                                <b-input v-model="variable.value" @change="onCypressPipelineVariablesChanged" :lazy="true"></b-input>
                                                            </template>
                                                        </b-col>
                                                        <b-col cols="1">
                                                            <e-button-confirm @confirmed="removeCypressPipelineVariable(index)" variant="link" class="text-dark">
                                                                <i class="fas fa-trash"></i>
                                                            </e-button-confirm>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-else>
                                                        <b-col>
                                                            <span>CYPRESS_{{ variable.name }}</span>
                                                        </b-col>
                                                        <b-col>
                                                            <span>{{ variable.value }}</span>
                                                        </b-col>
                                                    </b-row>

                                                </b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </div>
                                    <b-dropdown right variant="light" class="ml-2 float-right" no-caret>
                                        <template #button-content>
                                            Actions
                                            <i class="fas fa-ellipsis-v fa-fw"></i>
                                        </template>
                                        <b-dropdown-item link-class="text-danger" @click="removePipeline">Remove pipeline</b-dropdown-item>
                                    </b-dropdown>
                                </b-card>

                            </b-card-body>

                            <b-card-footer class="text-right">
                                <e-button-confirm variant="light" @confirmed="cancel()" class="mr-3">Cancel</e-button-confirm>
                                <b-btn @click="saveProject(project)" variant="success" :disabled="saving || !project.name">
                                    <b-spinner v-if="saving" variant="primary" small type="grow" label="Spinning"></b-spinner>
                                    Save changes
                                </b-btn>
                            </b-card-footer>
                        </b-card>
                    </b-tab>
                    <b-tab title="Raw kubernetes" @click="refreshCodeEditor">
                        <b-card class="text-left my-3 overflow-hidden" no-body>
                            <codemirror ref="config" v-model="project.yaml_config" :options="cmOptions"></codemirror>
                            <b-card-footer class="text-right">
                                <e-button-confirm variant="light" @confirmed="cancel()" class="mr-3">Cancel</e-button-confirm>
                                <b-btn @click="saveRawProject(project)" variant="success" :disabled="saving">
                                    <b-spinner v-if="saving" variant="primary" small type="grow" label="Spinning"></b-spinner>
                                    Save changes
                                </b-btn>
                            </b-card-footer>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </template>
        </b-overlay>

        <b-modal id="import-env-modal" ref="import-env-modal" v-model="showEnvImportModal" title="Import environment" size="lg" scrollable>
            <b-form-group label="Import from">
                <b-select v-model="clusterToImport">
                    <option v-for="cluster in $store.state.clusters" :value="cluster.id" :key="cluster.id">{{ cluster.id.toUpperCase() }}</option>
                </b-select>
            </b-form-group>

            <div class="text-center">
                <e-button-async variant="primary" @async-click="previewEnvImport">Preview Import <i class="fas fa-magnifying-glass-arrows-rotate"></i></e-button-async>
            </div>

            <b-list-group v-if="envToImport.length > 0" class="mt-2">
                <b-list-group-item v-for="envVar of envToImport" :key="envVar.name">
                    <b-row>
                        <b-col cols="5">
                            <b-checkbox v-model="envVar.import">{{ envVar.name }}</b-checkbox>
                        </b-col>
                        <b-col>
                            <b-input v-model="envVar.value"></b-input>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>

            <template #modal-footer>
                <b-btn variant="primary" @click="importEnv" :disabled="envToImport.length === 0">
                    Import
                    <i class="fas fa-save"></i>
                </b-btn>
            </template>
        </b-modal>
    </app-page>
</template>

<script>

import Network from "../../vue-components/helpers/Network.js";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import yaml from "js-yaml";
import {codemirror} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/yaml/yaml.js'
import EButtonAsync from "../../vue-components/components/e-button-async.vue";
import TagsInput from "@voerro/vue-tagsinput";
import "@voerro/vue-tagsinput/dist/style.css"
import EDatetimePicker from "../../vue-components/components/e-datetime-picker.vue";
import moment from "moment";

export default {
    name: `project`,
    components: {EDatetimePicker, EButtonAsync, EButtonConfirm, codemirror, TagsInput},
    data() {
        return {
            loading: true,
            creationMode: false,
            project: null,
            projectNotExists: false,
            useDefaultDockerCommand: true,
            saving: false,
            rebooting: false,
            tabIndex: 0,
            cmOptions: {
                tabSize: 4,
                mode: `yaml`,
                theme: `monokai`,
                lineNumbers: true,
                line: true,
                styleActiveLine: true,
                viewportMargin: Infinity
            },
            pipelineTypes: [
                {'value': `jenkins`, 'text': `JENKINS`},
                {'value': `github_actions`, 'text': `GitHub Actions`}
            ],
            showEnvImportModal: false,
            clusterToImport: `dev`,
            envToImport: [],
        }
    },
    computed: {
        cypressScheduledTime() {
            if (!this.project?.cypressCronExpression) {
                return null;
            }

            const tmp = this.project.cypressCronExpression.trim().split(` `);
            const time = moment(`${tmp[1]}:${tmp[0]}:00.000Z`, `H:m:ssZ`)
            return time.local().format(`HH:mm`);
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init(true);
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        cancel() {
            window.location.reload();
        },
        init(doNotResetProject = false) {
            if (this.$route.params.name === `create`) {
                if (this.project && doNotResetProject) {
                    this.project.cluster = this.$store.state.cluster;
                    this.project.env = this.$store.getters.clusterObject.env;
                } else {
                    this.project = {
                        name: ``,
                        cluster: this.$store.state.cluster,
                        env: this.$store.getters.clusterObject.env,
                        dockerImage: ``,
                        replicas: 1,
                        environmentVariables: [],
                        services: [{
                            name: ``,
                            type: `ClusterIP`,
                            ports: [{
                                protocol: `TCP`,
                                port: 80
                            }]
                        }],
                        domains: [],
                        pods: [],
                        yaml_config: ``,
                        pipeline: null,
                        pipeline_status: `unchanged`,
                        initialPipeline: null,
                        cypressPipelineVariables: [],
                        cypressCronExpression: null
                    }
                }

                this.useDefaultDockerCommand = this.project.dockerCommand === null;
                this.creationMode = true;
                this.loading = false;
            } else {
                this.project = null;
                this.getProject();
            }
        },
        getProject() {
            this.loading = true;
            Network.get(`/api/project/${this.$store.state.cluster}/${this.$route.params.name}`)
                .then(resp => {
                    const project = resp.data;
                    for (const variable of project.environmentVariables) {
                        variable.values = [];
                        try {
                            const value = JSON.parse(variable.value);
                            if (Array.isArray(value)) {
                                variable.values = value;
                                variable.type = `Array`;
                            } else {
                                variable.type = `Text`;
                            }
                        } catch {
                            variable.type = `Text`;
                        }
                    }

                    this.$set(this, `project`, project);
                    this.project.initialPipeline = structuredClone(project.pipeline);
                    this.useDefaultDockerCommand = this.project.dockerCommand === null;
                    this.projectNotExists = false;
                    this.creationMode = false;
                    this.projectChanged();
                    this.loading = false;
                })
                .catch(() => {
                    this.project = null;
                    this.projectNotExists = true;
                    this.creationMode = false;
                    this.loading = false;
                });
        },
        startDeployment() {
            const copyEnv = this.$store.state.cluster === `dev` ? `live` : `dev`;
            Network.get(`/api/project/${copyEnv}/${this.$route.params.name}`)
                .then(resp => {
                    this.project = resp.data;
                    this.project.env = this.$store.state.cluster;
                    this.project.replicas = 1;
                    this.project.rawDeployment = null;
                    this.project.rawServices = null;

                    this.useDefaultDockerCommand = this.project.dockerCommand === null;

                    if (!this.useDefaultDockerCommand) {
                        this.project.dockerCommand = this.project.dockerCommand.replace(copyEnv, this.$store.state.cluster);
                    }
                    this.project.pods = [];

                    this.project.domains = this.project.domains.map(d => d.replace(/^dev\./g, `demo.`))

                    this.projectNotExists = false;
                    this.creationMode = true;
                    this.projectChanged();
                    this.loading = false;
                })
        },
        refreshCodeEditor() {
            this.$refs.config.refresh();
        },
        projectNameChanged() {
            this.project.dockerImage = `eyeinmedia/${this.project.name}`;
            const service = this.project.services.find(s => s.ports.find(p => p.port === 80 && p.protocol === `TCP`));
            if (service) {
                service.name = this.project.name;
            }

            this.projectChanged();
        },
        kubernetesFormatter(value) {
            return value.toLowerCase().replace(/\s/g, `-`)
        },
        downloadLogs(podName) {
            Network.get(`/api/pod/logs/${this.$store.state.cluster}/${podName}`)
                .then(resp => {
                    const blob = new Blob([resp.data], {type: `text/raw`});
                    const filename = podName + `.log`
                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const elem = document.createElement(`a`);
                        elem.href = URL.createObjectURL(blob);
                        elem.download = filename;
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                });
        },
        addDomain() {
            this.$set(this.project, `edit_domains`, true);

            if (!this.project.domains.includes(``)) {
                this.project.domains.push(``);
            }

            this.projectChanged();
        },
        editDomains() {
            this.$set(this.project, `edit_domains`, !this.project.edit_domains);
        },
        removeDomain(index) {
            this.project.domains.splice(index, 1);
            this.projectChanged();
        },
        rebootPods() {
            if (!this.isLocal() && this.project.name === `hosting-panel`) {
                this.showErrorMessage(`Error`, `Hosting panel pod can only be rebooted from local environment (Current env: ${this.getEnv()}).`)
                return
            }

            this.rebooting = true;
            Network.post(`/api/pods/reboot/${this.$store.state.cluster}/${this.project.name}`)
                .then(() => {
                    this.rebooting = false;
                    this.getProject();
                });
        },
        addEnvironmentVariable() {
            this.$set(this.project, `edit_env`, true);

            if (!this.project.environmentVariables.find(v => v.name === ``)) {
                this.project.environmentVariables.push({
                    type: `Text`,
                    name: ``,
                    value: ``,
                    values: []
                });

                this.projectChanged();
            }
        },
        addFirewallEnvironmentVariable() {
            this.$set(this.project, `edit_env`, true);

            if (!this.project.environmentVariables.find(v => v.name === `PROJECT_USE_FIREWALL`)) {
                this.project.environmentVariables.push({
                    type: `Text`,
                    name: `PROJECT_USE_FIREWALL`,
                    value: `no`,
                    values: []
                });

                this.projectChanged();
            }
        },
        editEnvironmentVariable() {
            this.$set(this.project, `edit_env`, true);
        },
        removeEnvironmentVariable(index) {
            this.project.environmentVariables.splice(index, 1);
            this.projectChanged();
        },
        addCypressPipelineVariable() {
            this.$set(this.project, `edit_pipeline_var`, true);

            if (!this.project.cypressPipelineVariables.find(v => v.name === ``)) {
                this.project.cypressPipelineVariables.push({
                    name: ``,
                    value: ``
                });

                this.projectChanged();
            }
        },
        editCypressPipelineVariable() {
            this.$set(this.project, `edit_pipeline_var`, true);
        },
        removeCypressPipelineVariable(index) {
            this.project.cypressPipelineVariables.splice(index, 1);
            this.projectChanged();
            this.project.pipeline_status = `changed`;
        },
        addService() {
            this.$set(this.project, `edit_services`, true);

            if (!this.project.services.find(s => s.name === ``)) {
                this.project.services.push({
                    name: ``,
                    type: `ClusterIP`,
                    ports: [{
                        protocol: `TCP`,
                        port: 80
                    }]
                });

                this.projectChanged();
            }
        },
        editServices() {
            this.$set(this.project, `edit_services`, true);
        },
        removeService(index) {
            this.project.services.splice(index, 1);
            this.projectChanged();
        },
        addPort(service) {
            if (!service.ports.find(p => p.port === ``)) {
                service.ports.push({
                    protocol: `TCP`,
                    port: ``
                });
                this.projectChanged();
            }
        },
        removePort(service, index) {
            service.ports.splice(index, 1);
            this.projectChanged();
        },
        addPipeline(type) {
            const pipelineType = this.pipelineTypes.find(p => p.value === type);
            this.project.pipeline = {
                type: type,
                name: pipelineType.text,
                includeCypressAction: false
            };

            if (this.project.initialPipeline) {
                this.project.pipeline.cypressRecordKey = this.project.initialPipeline.cypressRecordKey;
            }

            if (!this.project.initialPipeline || this.project.pipeline.type !== this.project.initialPipeline.type
                || this.project.pipeline.type === this.project.initialPipeline.type
                && this.project.pipeline.includeCypressAction !== this.project.initialPipeline.includeCypressAction) {
                this.project.pipeline_status = `changed`;
            }
        },
        removePipeline() {
            this.$set(this.project, `pipeline`, null);
        },
        onCypressActionChanged() {
            if (this.project.initialPipeline
                && this.project.pipeline.includeCypressAction !== this.project.initialPipeline.includeCypressAction) {
                this.project.pipeline_status = `changed`;
            } else if (!this.project.initialPipeline) {
                this.project.pipeline_status = `changed`;
            } else {
                this.project.pipeline_status = `unchanged`;
            }
        },
        onCypressRecordKeyChanged(value) {
            if (this.project.initialPipeline && value === this.project.initialPipeline.cypressRecordKey) {
                this.project.pipeline_status = `unchanged`;
            } else {
                this.project.pipeline_status = `changed`;
            }
        },
        onCypressPipelineVariablesChanged() {
            this.project.pipeline_status = `changed`;
        },
        onCypressCronExpressionChanged(value) {
            const utcTime = moment(value, `HH:mm`).utc();
            this.project.cypressCronExpression = `${utcTime.format(`m H`)} * * *`
            this.project.pipeline_status = `changed`;
        },
        save() {
            if (this.tabIndex === 1) {
                this.saveRawProject();
            } else {
                this.saveProject();
            }
        },
        saveProject() {
            this.saving = true;

            if (this.useDefaultDockerCommand) {
                this.project.dockerCommand = null;
            }

            if (this.creationMode) {
                Network.post(`/api/project/create`, this.project)
                    .then((resp) => {
                        this.saving = false;
                        this.creationMode = false;
                        this.$router.push(`/project/${this.project.name}`);
                        this.notifyWhenResponseContainsError(resp.data)
                    })
                    .catch(e => {
                        this.saving = false;
                        this.showErrorMessage(`Error`, e.response.data);
                        console.error(e);
                    });
            } else {
                Network.put(`/api/project/edit`, this.project)
                    .then((resp) => {
                        this.notifyWhenResponseContainsError(resp.data)
                        this.getProject();
                    })
                    .catch(e => {
                        this.showErrorMessage(`Error`, e.response.data);
                        console.error(e);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            }
        },
        saveRawProject() {
            this.saving = true;
            Network.put(`/api/kubectl/apply`, {
                cluster: this.$store.state.cluster,
                yaml_config: this.project.yaml_config
            }).finally(() => {
                this.saving = false;
                this.getProject();
            });
        },
        deleteProject() {
            this.saving = true;
            Network.delete(`/api/project/${this.$store.state.cluster}/${this.project.name}`).then((resp) => {
                this.saving = false;
                this.$router.push(`/projects`);
                this.notifyWhenResponseContainsError(resp.data)
            });
        },
        projectChanged() {
            let existingDeployment = null;
            let existingServices = [];

            for (const variable of this.project.environmentVariables) {
                if (variable.type === `Array` || variable.type === `Object`) {
                    variable.value = JSON.stringify(variable.values);
                }
            }

            if (this.project.yaml_config) {
                const objects = yaml.safeLoadAll(this.project.yaml_config);
                existingDeployment = objects.find(o => o.kind === `Deployment`);
                existingServices = objects.filter(o => o.kind === `Service`);
            }

            let deployment;
            if (existingDeployment) {
                deployment = existingDeployment;
                deployment.metadata.name = this.project.name;
                deployment.spec.selector.matchLabels.app = this.project.name;
                deployment.spec.template.metadata.labels.app = this.project.name;

                deployment.spec.template.spec.containers[0].name = this.project.name;
                deployment.spec.template.spec.containers[0].image = this.project.dockerImage;
            } else {
                deployment = {
                    apiVersion: `apps/v1`,
                    kind: `Deployment`,
                    metadata: {
                        name: this.project.name,
                        namespace: `default`,
                        annotations: {}
                    },
                    spec: {
                        replicas: this.project.replicas,
                        selector: {
                            matchLabels: {
                                app: this.project.name
                            }
                        },
                        template: {
                            metadata: {
                                labels: {
                                    app: this.project.name
                                }
                            },
                            spec: {
                                containers: [{
                                    name: this.project.name,
                                    image: this.project.dockerImage,
                                    imagePullPolicy: `Always`,
                                    imagePullSecrets: [{
                                        name: `dockerhub-credentials`
                                    }],
                                    envFrom: [{
                                        secretRef: {
                                            name: `${this.project.env}-secrets`
                                        }
                                    }]
                                }]
                            }
                        }
                    }
                }
            }

            if (this.project.dockerCommand) {
                deployment.spec.template.spec.containers[0].command = this.project.dockerCommand.split(` `);
            }

            let containerPorts = {};

            let services = [];
            for (let projectService of this.project.services) {
                const existingService = existingServices.find(s => s.metadata.name === projectService.name);

                for (const p of projectService.ports) {
                    containerPorts[`${p.protocol}-${p.port}`] = {
                        containerPort: p.port,
                        protocol: p.protocol
                    }

                    if (p.name) {
                        containerPorts[`${p.protocol}-${p.port}`].name = p.name
                    }
                }

                const servicePorts = projectService.ports.map(p => {
                    let port = {
                        port: p.port,
                        protocol: p.protocol,
                        targetPort: p.port
                    }

                    if (p.name) {
                        port.name = p.name;
                        port.targetPort = p.name;
                    }

                    return port;
                });

                if (existingService) {
                    existingService.spec.ports = servicePorts;
                    existingService.spec.type = projectService.type;

                    if (projectService.type === `LoadBalancer`) {
                        existingService.metadata.annotations[`service.beta.kubernetes.io/azure-load-balancer-resource-group`] = `applications-ip-resource-group`;
                        existingService.spec.loadBalancerIP = projectService.ip;
                    } else {
                        delete existingService.metadata.annotations[`service.beta.kubernetes.io/azure-load-balancer-resource-group`];
                        delete existingService.spec.loadBalancerIP;
                    }

                    services.push(existingService);
                } else {
                    let service = {
                        apiVersion: `v1`,
                        kind: `Service`,
                        metadata: {
                            name: this.project.name,
                            namespace: `default`,
                            annotations: {}
                        },
                        spec: {
                            type: projectService.type,
                            selector: {
                                app: this.project.name
                            },
                            ports: servicePorts
                        }
                    }

                    if (projectService.type === `LoadBalancer`) {
                        service.metadata.annotations[`service.beta.kubernetes.io/azure-load-balancer-resource-group`] = `applications-ip-resource-group`;
                        service.spec.loadBalancerIP = projectService.ip;
                    }

                    services.push(service);
                }
            }

            deployment.spec.template.spec.containers[0].ports = Object.values(containerPorts);

            this.project.yaml_config = yaml.dump(deployment) + `---\n` + services.map(s => yaml.dump(s)).join(`\n---\n`);
        },
        notifyWhenResponseContainsError(data) {
            let responseContainsError = false;
            for (let taskName in data) {
                const task = data[taskName];
                if (task.status === `failed`) {
                    console.error(`${taskName} - ${task.error}`);
                    responseContainsError = true;
                }
            }
            if (responseContainsError) {
                this.showErrorMessage(`Error`, `See console for error(s)`)
            } else {
                this.showSavedMessage()
            }
        },
        previewEnvImport(callback) {
            this.envToImport = [];
            Network.get(`/api/project/${this.clusterToImport}/${this.$route.params.name}`)
                .then(resp => {
                    const otherProject = resp.data;
                    for (const envVar of otherProject.environmentVariables) {
                        const envVarAlreadyExists = this.project.environmentVariables.find(v => v.name === envVar.name);

                        envVar.import = true;
                        if (!envVarAlreadyExists) {
                            this.envToImport.push(envVar);
                        }
                    }

                    callback();
                });
        },
        importEnv() {
            for (const envVar of this.envToImport) {
                if (!envVar.import) {
                    continue;
                }

                this.project.environmentVariables.push(envVar);
            }

            this.showEnvImportModal = false;
        }
    }
};
</script>

<style>
.CodeMirror {
    height: auto;
}
</style>
